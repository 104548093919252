<template>
	<div class="team">
		<SubHeader title="Teams" subtitle="チーム紹介" />

		<div class="container">
			<div class="row mb-4">
				<div
					class="col-lg-2 col-6 text-center pb-4"
					v-for="team in teams"
					:key="'team' + team.id"
				>
					<p>
						<img
							:src="'images/teams/logo/' + team.code + '.jpg'"
							class="rounded-circle team_logo"
							:alt="team.name"
							@click="viewTeamDetail(team.id)"
						/>
					</p>
					<h3 class="mb-0">{{ team.name }}</h3>
					<p>
						<span class="code badge badge-primary">{{ team.code }}</span>
					</p>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-lg-12 text-center">
					<div class="alert" role="alert">
						<h4 class="alert-heading">
							<i class="fas fa-info-circle mr-2"></i
							>チームロゴ＆集合写真の更新について
						</h4>
						<p>
							以下を基準に、
							<a href="https://form.run/@odo-contact" target="_blank"
								>問い合わせフォーム</a
							>等から画像ファイルを送って頂ければ更新・掲載させて頂きます。
						</p>
						<hr />
						<p>
							チームロゴ：「400px x 400px」のjpg/png形式を推奨しております。
							<br />集合写真：「600px x 400px」のjpg/png画像を推奨しております。
						</p>
					</div>
				</div>
			</div>
		</div>

		<modal
			class="team_detail"
			name="team_detail"
			:adaptive="true"
			width="100%"
			height="auto"
			:maxWidth="1000"
			:scrollable="true"
		>
			<div class="row">
				<div class="team__picture col-md-4 col-12">
					<h4>
						{{ team.name }}
						<span class="code badge badge-primary ml-2">{{ team.code }}</span>
					</h4>
					<img
						:src="'images/teams/picture/' + team.code + '.jpg'"
						class="img-fluid"
					/>
				</div>
				<div class="team__member col-md-8 col-12">
					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">Number</th>
									<th scope="col" class="text-nowrap">Name</th>
									<th scope="col" class="text-nowrap">Kana</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(player, index) in team.members"
									:key="'player' + index"
								>
									<td scope="row" class="text-nowrap">{{ index + 1 }}</td>
									<td scope="row" class="text-nowrap">{{ player.key }}</td>
									<td scope="row" class="text-nowrap">{{ player.name }}</td>
									<td scope="row" class="text-nowrap">{{ player.kana }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="text-center d-lg-none d-md-none">
						<a class="btn btn-contact mb-3" @click="$modal.hide('team_detail')">
							<i class="fas fa-window-close"></i> 閉じる
						</a>
					</div>
				</div>
			</div>
		</modal>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VModal from "vue-js-modal";
Vue.use(Loading);
Vue.use(VModal);

export default {
	name: "team",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			teams: [],
			team: [],
		};
	},
	mounted: function () {
		this.getTeams();
	},
	computed: {},
	methods: {
		getTeams: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {};
			this.axios
				.get("https://admin.darts-l.com/api/odo/teams", {
					params: params,
				})
				.then((res) => {
					//console.log("team:" + JSON.stringify(res.data));
					this.teams = res.data;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
		viewTeamDetail: function (team_id) {
			this.team = this.teams["team" + team_id];
			this.$modal.show("team_detail");
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
	font-size: 1rem;
}
h4 {
	font-size: 1.2rem;
}
img.team_logo {
	max-width: 70%;
	height: auto;
}
span.code {
	font-size: 80%;
}
.container {
	padding: 4rem 1rem;
	text-align: left;
}
.team__picture {
	padding: 1rem 0.5rem 0 3rem;
	@include sp {
		padding: 1rem 2rem 1rem 2rem;
	}
}
.team__member {
	padding: 1rem 2rem 0 1rem;
	@include sp {
		padding: 0 2rem 5rem 2rem;
		margin-bottom: 1rem;
	}
	td,
	th {
		@include sp {
			font-size: 90%;
		}
	}
}
.alert {
	background-color: $secondary-color;
	h4 {
		font-weight: bold;
	}
	p {
		color: #333;
	}
}
</style>
